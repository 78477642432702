<template>
  <div class="container">
    <PublicHeader totalName="ETF" :noLeft="true" />
    <!-- 产品 名称-->
    <div class="proName">
      <div class="pro">
        <img src="@/assets/img/exchange.png" width="17" @click="showProList = true" />
        <div class="name">{{ proInfo.Name }}</div>
        <span class="flu_red" v-if="proInfo.IsRise">{{ proInfo.Fluctuation }}%</span>
        <span class="flu_green" v-else>{{ proInfo.Fluctuation }}%</span>
      </div>
      <img src="@/assets/img/kline_ico.png" width="20"
        @click="$router.push({ name: 'EtfInfo', query: { code: NowCode } })" />
    </div>
    <!-- 产品数据 -->
    <div class="proNum">
      <div class="title">{{ $t('etf').Balance }}（USDT）<span>{{ $t('etf').canUse }}（USDT）</span><span>{{
        $t('etf').riskRate }}</span></div>
      <div class="num">0.0000 <span>0.0000</span><span>0%</span></div>
    </div>
    <!-- 交易页面 -->
    <div class="tradeInfo">
      <div class="left">
        <div class="num">
          <!-- 交易类型 -->
          <van-popover v-model="showPopover_tradeType" placement="bottom-start" :actions="actions_tradeType"
            @select="onSelect_TradeType">
            <template #reference>
              <div class="t1" @click="showPopover_tradeType = !showPopover_tradeType"><van-field
                  :placeholder="TradeType == 'Present' ? $t('etf').Present : $t('etf').Limit" readonly
                  right-icon="arrow-down"></van-field></div>
            </template>
          </van-popover>
          <!-- 杠杆倍数 -->
          <van-popover v-model="showPopover_Lever" placement="bottom-start" :actions="actions_Lever"
            @select="onSelect_Lever">
            <template #reference>
              <div class="t2" @click="showPopover_Lever = !showPopover_Lever"><van-field :placeholder="Lever + 'x'"
                  readonly right-icon="arrow-down"></van-field></div>
            </template>
          </van-popover>
        </div>
        <!-- 市价 -->
        <div class="lable" v-if="TradeType == 'Present'">{{ $t('etf').markPriceMsg }}</div>
        <!-- 限价 -->
        <van-field v-model="LimitPrice" :placeholder="$t('etf').limitMsg" v-if="TradeType == 'Limit'"> </van-field>
        <!-- 数量 -->
        <van-field v-model="Num" :placeholder="$t('etf').num">
          <template #right-icon>
            <span style="color: #333333">{{ $t('etf').numTip }}</span>
          </template>
        </van-field>
        <!-- 选择百分比 -->
        <div class="precent">
          <span v-for="(item, index) in selectPrecentArr" :key="`precent${index}`" @click="handlePrecent(index)"
            :class="{ activePrecent: activePrecent === index }">
            {{ item }}%
          </span>
        </div>
        <!-- 止盈止损 -->
        <div class="lable2">{{ $t('etf').zyzsSet }}</div>
        <div class="num">
          <!-- 止盈价 -->
          <van-field v-model="StopSurplusPrice" :placeholder="$t('etf').zyPrice" />
          <!-- 止损价 -->
          <van-field style="margin-left: 3px;" v-model="StopLossPrice" :placeholder="$t('etf').zsPrice" />
        </div>
        <!-- 开多 -->
        <van-button color="#35B56D" block style="border-radius: 5px;height: 32px;font-size: 13px;" @click="handleSub">
          {{ $t('etf').Openmore }}</van-button>
        <!-- 开空 -->
        <van-button color="#C92B2E" block style="border-radius: 5px;height: 32px;font-size: 13px;margin-top: 10px;"
          @click="handleSub">{{ $t('etf').Openempty }}</van-button>
        <!-- 可开 -->
        <div class="txt">{{ $t('etf').canNum }}<span>0.00{{ $t('etf').numTip }}</span></div>
        <!-- 保证金 -->
        <div class="txt">{{ $t('etf').EstimatedMargin }}<span>0.00USDT</span></div>
      </div>
      <div class="right">
        <div class="title">{{ $t('etf').price }}<span>{{ $t('etf').num }}</span></div>
        <div class="item_buy" v-for="(dItem, Index) in buyList" :key="`buy${Index}`">{{ dItem.price }} <span>{{
          dItem.amount
            }}</span></div>
        <div class="price" :class="proInfo.IsRise ? 'red' : 'green'">{{ proInfo.LastPrice }}</div>
        <div class="item_sell" v-for="(dItem, Index) in sellList" :key="`sell${Index}`">{{ dItem.price }} <span>{{
          dItem.amount
            }}</span></div>
      </div>
    </div>
    <div class="line"></div>
    <!-- 切换 -->
    <div class="tabs">
      <div class="item" :class="{ active: tabActive === 0 }" @click="tabActive = 0">{{ $t('etf').tradeList }}</div>
      <div class="item" :class="{ active: tabActive === 1 }" @click="tabActive = 1">{{ $t('etf').haveTrade }}(0)</div>
      <div class="item" :class="{ active: tabActive === 2 }" @click="tabActive = 2">{{ $t('etf').nowTrade }}(0)</div>
    </div>
    <!-- 交易记录 -->
    <div class="transactions" v-if="tabActive == 0">
      <div class="title">{{ $t('etf').time }} <span>{{ $t('etf').direction }}</span><span>{{ $t('etf').price
          }}</span><span>{{ $t('etf').num }}</span></div>
      <div class="item" v-for="(dItem, dIndex) in AllList" :key="`tran${dIndex}`">
        <div class="time">{{ dItem.ts | getHourTime }}</div>
        <div class="direction" :class="dItem.direction == 'buy' ? 'green' : 'red'">{{ dItem.direction == 'buy' ?
          $t('etf').buy :
          $t('etf').sell }}</div>
        <span>{{ dItem.price }}</span><span>{{ dItem.amount }}</span>
      </div>
    </div>
    <div style="padding: 50px 0;text-align: center;" v-if="tabActive != 0">
      <img src="@/assets/img/nodata.png" width="64" />
    </div>
    <!-- 产品列表 -->
    <van-popup v-model="showProList" closeable position="left">
      <div class="pro_list">
        <div class="name">ETF</div>
        <!-- 头部 -->
        <div class="title">{{ $t('etf').product }}<span>{{ $t('etf').newProce }}</span><span>{{ $t('etf').Fluctuation
            }}</span></div>
        <div class="item" v-for="(dItem, dIndex) in pList" :key="`pro${dIndex}`" @click="selectProduct(dItem.Code)">
          <div class="pname">{{ dItem.Name }}/USDT</div>
          <div class="price">{{ dItem.LastPrice }}</div>
          <div :class="dItem.IsRise ? 'red' : 'green'">{{ dItem.Fluctuation }}%</div>
        </div>
      </div>
    </van-popup>
    <TabBar :active="'Etf'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import productApi from '@/api/product'
import TabBar from '@/components/tabBar'
import { Token } from '@/utils/mm'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader,
    TabBar
  },
  data() {
    return {
      TradeType: 'Present',
      showPopover_tradeType: false,
      actions_tradeType: [
        { text: this.$t('etf').Present, val: 'Present' },
        { text: this.$t('etf').Limit, val: 'Limit' }
      ],
      Lever: 100,
      showPopover_Lever: false,
      actions_Lever: [
        { text: '100x', val: 100 },
        { text: '50x', val: 50 },
        { text: '30x', val: 30 },
        { text: '10x', val: 10 },
      ],
      Num: null,
      LimitPrice: null,
      activePrecent: null,
      selectPrecentArr: [25, 50, 70, 100],
      showProList: false,
      StopSurplusPrice: null,//止盈价格
      StopLossPrice: null,//止损价格
      pList: [],
      NowCode: 'BTC',
      tabActive: 0,
      proInfo: {},
      buyList: [{}],
      sellList: [{}],
      AllList: [],
      width: '100%',
      height: '82vw',
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    if (this.$route.query.code) {
      this.NowCode = this.$route.query.code
    }
    this.signalRStart()
    this.goDetail()
    this.GetTradeSecond()
    this.getProductTrade()
  },
  methods: {
    // 选择产品
    selectProduct(code) {
      this.showProList = false
      this.NowCode = code
      this.goDetail()
      this.GetTradeSecond()
      this.getProductTrade()
    },
    // 选择百分比
    handlePrecent(index) {
      this.activePrecent = index
      //市价
      if (this.TradeType == 'Present') {
        this.Num = parseInt(((this.selectPrecentArr[index] / 100) * 100000000) / this.proInfo.LastPrice)
      } else {
        this.Num = parseInt(((this.selectPrecentArr[index] / 100) * 100000000) / this.LimitPrice)
      }
    },
    // 选择类型
    async onSelect_TradeType(action) {
      this.TradeType = action.val
    },
    // 选择杠杆倍数
    async onSelect_Lever(action) {
      this.Lever = action.val
    },
    // 获取交易产品列表
    async getProductTrade() {
      const res = await productApi.getProductTrade()
      this.pList = res.Data
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
    //产品详情
    async goDetail() {
      const res = await productApi.getProductDetail({ code: this.NowCode })
      this.proInfo = res.Data
    },
    //产品成交盘数据
    async GetTradeSecond() {
      const res = await productApi.GetTradeSecond({ code: this.NowCode })
      if (res.Data) {
        this.buyList = res.Data.Buy.slice(0, 5)
        this.sellList = res.Data.Sell.slice(0, 5)
        this.AllList = res.Data.Buy.concat(res.Data.Sell).sort((a, b) => b.ts - a.ts).slice(0, 20)
      }
    },
    //提交
    handleSub() {
      this.$toast(this.$t('etf').cloedMsg)
    },
    //推送
    signalRStart() {
      let that = this
      const connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      //买卖盘
      connection.on('secondTrades', function (res) {
        res.forEach((item) => {
          if (item.Code === that.proInfo.Code) {
            that.buyList = item.Buy.slice(0, 5)
            that.sellList = item.Sell.slice(0, 5)
            that.AllList = item.Buy.concat(item.Sell).sort((a, b) => b.ts - a.ts).slice(0, 20)
          }
        })
      })
      //最新价格
      connection.on('secondPrices', function (res) {
        res.forEach((item) => {
          //产品列表
          that.pList.forEach((sItem) => {
            if (item.Code === sItem.Code && item.Price > 0) {
              sItem.LastPrice = item.Price
              sItem.Fluctuation = item.Fluctuation
            }
          })
          //产品详情
          if (item.Code === that.proInfo.Code) {
            that.proInfo.LastPrice = item.Price
            that.proInfo.Fluctuation = item.Fluctuation
            that.proInfo.High = item.High
            that.proInfo.Low = item.Low
            that.proInfo.Vol = item.Vol
            that.proInfo.IsRise = item.IsRise
          }
        })
      })
    },

  }
}
</script>

<style lang="less" scoped>
.red {
  color: #C92B2E !important;
}

.green {
  color: #35B56D !important;
}

.container {
  min-height: 100vh;
  font-size: 14px;
  padding: 65px 0 70px;
  background: #ffffff;

  & /deep/ .van-popup--left {
    top: 65px;
    height: calc(100vh - 50px) !important;
    width: 65%;
    transform: translate3d(0, 0, 0);
    border-radius: 0 10px 0 0;
  }

  & /deep/ .van-popup__close-icon {
    color: #000000;
  }

  .proName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 13px;

    .pro {
      display: flex;
      align-items: center;

      img {
        margin-bottom: 3px;
      }

      .name {
        margin-left: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      .flu_red {
        margin-left: 10px;
        width: 61px;
        height: 25px;
        background: #FFE8E8;
        border-radius: 3px;
        border: 1px solid #FFC6C6;
        font-weight: bold;
        font-size: 13px;
        color: #C92B2E;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .flu_green {
        margin-left: 10px;
        width: 61px;
        height: 25px;
        background: #c6fdde;
        border-radius: 3px;
        border: 1px solid #9eecc0;
        font-weight: bold;
        font-size: 13px;
        color: #35B56D;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }


  }

  .proNum {
    margin: 10px 13px;
    padding: 10px 18px;
    border-radius: 5px;
    background: #DF3125;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #FFBDBD;
    }

    .num {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
    }
  }

  .tradeInfo {
    margin: 0 13px 5px 13px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 50%;

      .num {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .t1 {
          width: 101px;
        }

        .t2 {
          width: 65px;
        }
      }

      .lable {
        margin: 3px 0 10px 0;
        font-weight: bold;
        font-size: 13px;
        color: #333333;
      }

      .precent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          width: 39px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #DBDBDB;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #333333;
        }

        .activePrecent {
          color: #fff;
          background: #DF3125;
        }
      }

      .lable2 {
        margin: 10px 0;
        font-weight: bold;
        font-size: 13px;
        color: #333333;
      }

      .txt {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        color: #999999;
      }
    }

    .right {
      width: 50%;
      margin-left: 10px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #999999;
        margin-bottom: 5px;
      }

      .item_buy {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        color: #35B56D;
        padding: 7.7px 0;
        border-bottom: 1px solid #E3E3E3;

        span {
          font-weight: 500;
          font-size: 13px;
          color: #999999;
        }
      }

      .price {
        margin: 10px 0;
        font-weight: bold;
        font-size: 15px;
        color: #E83336;
      }

      .item_sell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        color: #E83336;
        padding: 7.7px 0;
        border-top: 1px solid #E3E3E3;

        span {
          font-weight: 500;
          font-size: 13px;
          color: #999999;
        }
      }

    }
  }

  .line {
    height: 4px;
    background: #F1F1F1;
  }

  .tabs {
    display: flex;
    align-items: center;
    padding: 13px;

    .item {
      margin-right: 30px;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .active {
      color: #C92B2E;
    }
  }


  .transactions {
    padding: 0 13px;

    .title {
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #999999;
    }

    .item {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #999999;
      border-bottom: 1px solid #E3E3E3;

      .time {
        width: 30%;
      }

      span {
        width: 30%;
        text-align: right;
      }

      .direction {
        text-align: center;
        width: 10%;
      }
    }
  }

  .pro_list {
    margin: 0 13px;
    padding: 20px 0;
    background: #fff;
    border-radius: 5px;

    .name {
      font-weight: bold;
      font-size: 17px;
      color: #333333;
    }

    .title {
      margin: 20px 0 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 13px;
        color: #999999;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E3E3E3;
      font-size: 13px;

      .pname {
        font-weight: bold;
        color: #333333;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        color: #999999;
      }
    }
  }
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  height: 32px;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 13px;
  height: 32px;
  padding: 5px 7px;
}
</style>
